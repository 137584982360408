import { CurrencyPipe } from '@angular/common'
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { ValidateBrService } from 'angular-validate-br'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { PlanoEmpresa } from 'src/app/model/interfaces/inclusao-vida-popup'
import { patternCaracteresEspeciaiseEspaco } from 'src/app/model/vidas/vidas.enum'
import { CompromissoService } from 'src/app/service/compromisso.service'
import { Titularidade } from 'src/app/utils/enums'
import { formatarStringParaCamelCase, setValidators } from 'src/app/utils/util'

@Component({
	selector: 'app-migrar-plano-form-vida',
	templateUrl: './migrar-plano-form-vida.component.html',
	styleUrls: ['./migrar-plano-form-vida.component.scss'],
	providers: [CurrencyPipe],
})
export class MigrarPlanoFormVidaComponent implements OnInit, AfterViewInit {

	telefoneInformado: boolean = true
	buscandoCPF: boolean = false
	beneficiarioEncontrado: boolean
	beneficiarioEncontradoSearch: boolean = true
	exibirForm: boolean = false
	idEmpresa: number

	@Output() onCarregarPlanoEmpresaFromFormVidas = new EventEmitter<{ listaProdutos: PlanoEmpresa[], idPlanoBeneficiario: Number }>();

	@Input() cpfMigracaoBeneficiario: string
	@Input() formInformacoesVida: FormGroup
	public get getFormInformacoesVida() {
		return this.formInformacoesVida.controls
	}

	public get isDependente() {
		const controlsInformacoesVida = this.getFormInformacoesVida
		return (controlsInformacoesVida.grauParentesco.value !== Titularidade.Titular)
	}

	constructor(private compromissoService: CompromissoService,
		private validateBrService: ValidateBrService,
		public translateService: TranslateService) { }

	ngOnInit() {
		if (!this.formInformacoesVida) {
			this.formInformacoesVida = new FormGroup({
				cpf: new FormControl(null, [Validators.required, this.validateBrService.cpf]),
				nome: new FormControl(null, [Validators.required, Validators.minLength(10)]),
				nomeMae: new FormControl(null, [Validators.required]),
				dataNascimento: new FormControl(null, [Validators.required]),
				sexo: new FormControl(null, [Validators.required]),
				telefone: new FormControl(null, [Validators.required]),
				email: new FormControl(null, [Validators.email]),
				tipoVida: new FormControl(null),
				empresaVinculada: new FormControl(null),
				idEmpresa: new FormControl(null),
				titularVinculado: new FormControl(null),
				matricula: new FormControl(null),
				grauParentesco: new FormControl(null),
				idPlanoAtual: new FormControl(null)
			})

			this.beneficiarioEncontrado = false
		} else {
			this.exibirForm = true
			this.beneficiarioEncontrado = true
		}

		if (this.cpfMigracaoBeneficiario) {
			this.getFormInformacoesVida.cpf.setValue(this.cpfMigracaoBeneficiario)
			this.buscarBeneficiarioCadastradoS4E()
		}
		this.formInformacoesVida.disable()
		this.getFormInformacoesVida.cpf.enable()
	}

	ngAfterViewInit() {
		this.getFormInformacoesVida.cpf.statusChanges
			.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe(async () => {
				await this.buscarBeneficiarioCadastradoS4E()
			})
	}

	resetFormInformacoesVida() {
		this.formInformacoesVida.reset()
		this.exibirForm = false
	}

	async buscarBeneficiarioCadastradoS4E() {
		const cpfFormatado = this.getFormInformacoesVida.cpf.value?.replace(
			patternCaracteresEspeciaiseEspaco, '')

		if (cpfFormatado) {
			this.buscandoCPF = true
			this.beneficiarioEncontradoSearch = true
			this.exibirForm = false
			await this.compromissoService.listarBeneficiarioAtivoPorCpf(cpfFormatado)
				.then(data => {
					if (data) {
						const { beneficiario, empresa } = data
						this.idEmpresa = empresa.idEmpresa
						const controlsInformacoesVida = this.getFormInformacoesVida
						controlsInformacoesVida.tipoVida.setValue(beneficiario.vinculo)
						controlsInformacoesVida.empresaVinculada.setValue(empresa.nomeFantasia)
						controlsInformacoesVida.idEmpresa.setValue(empresa.idEmpresa)
						controlsInformacoesVida.titularVinculado.setValue(beneficiario.nomeTitular)
						controlsInformacoesVida.nome.setValue(beneficiario.nomeFormatado)
						controlsInformacoesVida.nomeMae.setValue(formatarStringParaCamelCase(beneficiario.nomeMae))
						controlsInformacoesVida.dataNascimento.setValue(beneficiario.dataNascimento)
						controlsInformacoesVida.idPlanoAtual.setValue(beneficiario.idPlano)
						controlsInformacoesVida.sexo.setValue(beneficiario.sexo)
						controlsInformacoesVida.matricula.setValue(beneficiario.matricula)

						controlsInformacoesVida.grauParentesco.setValue(
							this.translateService.instant(`vidas.grauparentesco.${beneficiario.grauParentesco}`))

						if (beneficiario.email) {
							controlsInformacoesVida.email.setValue(beneficiario.email)
						}
						if (beneficiario.telefone) {
							controlsInformacoesVida.telefone.setValue(beneficiario.telefone)
						}
						this.telefoneInformado = !!beneficiario.telefone

						this.carregarPlanoEmpresa(empresa.planos, beneficiario.idPlano)
						setValidators(controlsInformacoesVida.empresaVinculada, beneficiario.vinculo === Titularidade.Dependente ? [] : [Validators.required])
						setValidators(controlsInformacoesVida.titularVinculado, beneficiario.vinculo === Titularidade.Dependente ? [Validators.required] : [])
						setValidators(controlsInformacoesVida.matricula, beneficiario.vinculo === Titularidade.Dependente ? [] : [Validators.required])
						setValidators(controlsInformacoesVida.grauParentesco, beneficiario.vinculo === Titularidade.Dependente ? [Validators.required] : [])

						this.beneficiarioEncontrado = true
						this.exibirForm = true
					} else {
						this.beneficiarioEncontrado = false
						this.beneficiarioEncontradoSearch = false
					}
					this.buscandoCPF = false
				})
		}
	}

	carregarPlanoEmpresa(listaProdutos: PlanoEmpresa[], idPlanoBeneficiario: number) {
		this.onCarregarPlanoEmpresaFromFormVidas.emit({ listaProdutos, idPlanoBeneficiario })
	}
}
