<form [formGroup]="formPrimeiraInclusao">
	<div class="form-row">
		<div class="alert alert-warning mb-3 col-md-12 custom-alert-text d-flex"
			*ngIf="dadosInclusaoVidas.tipoVida === tipoVidaEnum.TITULAR">
			<img src="assets/icons/info-warn.svg" alt="info" class="incluir-beneficiario-info" />
			<span>
				<b>{{ 'vidas.modal_vida_avulsa.attention' | translate }}:</b>{{
				'vidas.modal_vida_avulsa.attention_msg' | translate }}
			</span>
		</div>
		<div class="header d-flex justify-content-between col-md-12">
			<h5 class="card-title">{{ 'vidas.modal_vida_avulsa.dados_gerais' | translate }}</h5>
		</div>
	</div>

	<div class="form-row">
		<div class="col">
			<simple-select name="vida_tipo" selectUpperLabel="{{ 'vidas.detail.tipo_vida' | translate }} *"
				placeholder="{{ 'vidas.placeholders.status_filter' | translate }}" [items]="opcoesTipoVida"
				formControlName="vida_tipo" (onSelect)="tipoVidaSelecionado($event)" bindLabel="descricao"
				bindValue="value" ngDefaultControl>
			</simple-select>
		</div>
	</div>
	<div class="form-row mt-2" *ngIf="!getFormPrimeiraInclusao.vida_tipo.value">
		<div class="form-group col">
			<label class="form-label mb-1 font-weight-normal">* {{ 'vidas.detail.campo_obrigatorio' |
				translate }}</label>
		</div>
	</div>

	<div *ngIf="getFormPrimeiraInclusao.vida_tipo.value">
		<div *ngIf="dadosInclusaoVidas.tipoVida === tipoVidaEnum.DEPENDENTE" class="form-row mt-2">
			<div class="col">
				<simple-select name="cli_codigoassociados4e"
					selectUpperLabel="{{ 'vidas.detail.titular_vinculado' | translate }} *"
					placeholder="{{ 'vidas.placeholders.pesquisa_nome_cpf' | translate }}" [items]="opcoesTitulares"
					(onSelect)="titularSelecionado($event)" [loading]="buscandoColaboradoresTitulares"
					(onClear)="clearData()" formControlName="cli_codigoassociados4e" bindLabel="descricao"
					bindValue="value" [reloadItems]="listarBeneficiarioPorNomeOuCpf" ngDefaultControl>
				</simple-select>
			</div>
		</div>

		<div *ngIf="dadosInclusaoVidas.tipoVida == tipoVidaEnum.TITULAR" class="form-row mt-2">
			<div class="col">
				<simple-select name="vida_empresa"
					selectUpperLabel="{{ 'vidas.page_beneficiario.empresa_vinculada' | translate }} *"
					placeholder="{{'vidas.placeholders.pesquisa_nome_cnpj_empresa' | translate }}"
					[items]="opcoesEmpresa" (onSelect)="empresaVinculadaSelecionado($event)" [loading]="buscandoEmpresa"
					(onClear)="clearData()" formControlName="vida_empresa" bindLabel="descricao" bindValue="value"
					[reloadItems]="listarEmpresasPorNomeCnpjOuCodigo" ngDefaultControl>
				</simple-select>
				<div *ngIf="buscandoEmpresa" class="mt-2 warning">{{
					'vidas.page_beneficiario.buscando_empresas' | translate }}...</div>
				<div *ngIf="isEmpresaNaoEncontrada" class="mt-2 error"> {{
					'vidas.page_beneficiario.empresa_nao_encontrada' | translate }}...</div>
			</div>
		</div>

		<div class="form-row mt-2">
			<div class="col-6">
				<agisales-input id="vida_cpf" formControlName="vida_cpf"
					[placeholder]="'Informe o CPF do ' + (dadosInclusaoVidas.tipoVida == tipoVidaEnum.TITULAR ? 'titular' : 'dependente')"
					mask="CPF_CNPJ" label="CPF *" ngDefaultControl>
				</agisales-input>

				<div *ngIf="getFormPrimeiraInclusao.vida_cpf?.touched &&
						getFormPrimeiraInclusao.vida_cpf.errors?.cpfInvalid" class="mt-2 error">
					CPF {{ 'vidas.modal_vida_avulsa.invalido' | translate }}
				</div>

				<div *ngIf="buscandoCPF" class="mt-2 warning">{{ 'vidas.modal_vida_avulsa.verificando' |
					translate }} CPF...</div>
			</div>

			<div class="col-6 padding-top-3" *ngIf="dadosInclusaoVidas.tipoVida === tipoVidaEnum.DEPENDENTE">
				<simple-select name="cli_grauparentesco"
					selectUpperLabel="{{ 'vidas.detail.grau_parentesco' | translate }} *"
					placeholder="{{ 'vidas.detail.select_opcao' | translate }}" [items]="opcoesGrauParentesco"
					formControlName="cli_grauparentesco" [searchable]="false" bindLabel="descricao" bindValue="value"
					ngDefaultControl>
				</simple-select>
			</div>

			<div class="col-6" *ngIf="dadosInclusaoVidas.tipoVida === tipoVidaEnum.TITULAR">
				<agisales-input id="vida_matricula" formControlName="vida_matricula"
					placeholder="{{ 'vidas.placeholders.informe_matricula_titular' | translate }}"
					[label]="exigeMatricula ? ' Matrícula *' : 'Matrícula'" ngDefaultControl>
				</agisales-input>
			</div>
		</div>

		<div class="form-row mt-2">
			<div class="col">
				<agisales-input id="vida_nome" formControlName="vida_nome" [placeholder]="
						'Informe o nome completo do ' +
						(dadosInclusaoVidas.tipoVida == tipoVidaEnum.TITULAR ? 'titular' : 'dependente')
					" label="{{ 'vidas.detail.nome_completo' | translate }} *" ngDefaultControl>
				</agisales-input>
			</div>
		</div>

		<div class="form-row mt-2">
			<div class="col">
				<agisales-input id="vida_nomemae" formControlName="vida_nomemae" [placeholder]="
						'Informe o nome da mãe do ' +
						(dadosInclusaoVidas.tipoVida == tipoVidaEnum.TITULAR ? 'titular' : 'dependente')"
					label="{{ 'vidas.detail.nome_completo_mae' | translate }} *" ngDefaultControl>
				</agisales-input>
			</div>
		</div>

		<div class="form-row mt-2">
			<div class="col-6">
				<agisales-input mask="d0/M0/0000" id="vida_nascimento" formControlName="vida_nascimento"
					placeholder="Ex: 01/01/2000" label="{{ 'vidas.detail.data_nascimento' | translate }} *"
					ngDefaultControl>
				</agisales-input>
			</div>

			<div class="col-6 padding-top-3">
				<simple-select name="opcoesTipoSexo" selectUpperLabel="{{ 'vidas.detail.sexo' | translate }} *"
					[items]="opcoesTipoSexo" placeholder="{{ 'vidas.detail.select_opcao' | translate }}"
					formControlName="vida_sexo" bindLabel="descricao" bindValue="value" ngDefaultControl>
				</simple-select>
			</div>
		</div>

		<div class="form-row mt-2">
			<div class="col-6">
				<agisales-input id="vida_telefone" formControlName="vida_telefone"
					placeholder="{{ 'vidas.placeholders.informe_contato' | translate }}" mask="(00)00000-0000"
					label="{{ 'vidas.detail.telefone' | translate }} *" ngDefaultControl>
				</agisales-input>
			</div>

			<div class="col-6">
				<agisales-input id="vida_email" formControlName="vida_email" [placeholder]="
						'Informe o e-mail do ' +
						(dadosInclusaoVidas.tipoVida == tipoVidaEnum.TITULAR ? 'titular' : 'dependente')
					" label="E-mail" ngDefaultControl>
				</agisales-input>
			</div>
		</div>

		<div class="form-row mt-2">
			<div class="form-group col">
				<label class="form-label mb-1 font-weight-normal">* {{ 'vidas.detail.campo_obrigatorio' |
					translate}}</label>
			</div>
		</div>
	</div>
</form>

<default-swal #swalBeneficiarioJaCadastrado confirmButtonText="{{'vidas.modal_vida_avulsa.entendi' | translate}}"
	icon="warning" title="{{'vidas.modal_vida_avulsa.beneficiario_already_added' | translate}}" text=""
	(confirmCallback)="fecharSwalBeneficiarioJaCadastrado()" (cancelCallback)="fecharSwalBeneficiarioJaCadastrado()">
</default-swal>