export const ID_DE_GRUPOS_PERMISSAO = {
	ADMINISTRADOR_SALES: 35,
}

export const CONTEUDO_FOOTER_CLIN = `
  <div class="d-flex h-100 justify-content-between align-items-end mt-3">
                  <div class="d-flex flex-column w-100 border-top">
                      <span class="titulo font-weight-bold mt-4">A Clin na palma da sua mão</span>
                      <div class="d-flex flex-row my-4">
                          <div class="icon-lista-footer"></div>
                          <span class="font-weight-bold ml-2 subtitulo">Baixe o ClinApp</span>
                      </div>
                      <p class="descricao">
                          O maior plano odontológico do Norte e Nordeste é digital: o ClinApp foi criado<br> para oferecer uma
                          experiência mais rápida e fácil com a Clin.
                      </p>
                      <p>
                          Com apenas alguns cliques no app, solicite marcação de consultas, encontre as clínicas mais
                          próximas,<br> confira as coberturas do seu plano, acesse a carteira digital, inclua dependentes e
                          muito mais.
                      </p>
                  </div>

                  <div class="d-flex flex-row justify-content-end">
                    <img id="botao-play-store" class="icon-play-store mr-2" link-acesso="https://play.google.com/store/apps/details?id=clintech.clinapps.clincliente"/>
                    <img id="botao-app-store" class="icon-app-store" link-acesso="https://apps.apple.com/br/app/clinapp-clientes/id1196293191"/>
                  </div>
              </div>`

export const CONTEUDO_FOOTER_MOBILE_CLIN = `<div class="d-flex h-100 align-items-end mt-3">
  <div class="d-flex flex-column w-100">
      <span class="titulo font-weight-bold mt-1">A Clin na palma da sua mão</span>
      <div class="d-flex flex-row my-4">
          <div class="icon-lista-footer"></div>
          <span class="font-weight-bold ml-2 subtitulo">Baixe o ClinApp</span>
      </div>
      <p class="descricao">
          O maior plano odontológico do Norte e Nordeste é digital: o ClinApp foi criado para oferecer uma
          experiência mais rápida e fácil com a Clin.
      </p>
      <p>
          Com apenas alguns cliques no app, solicite marcação de consultas, encontre as clínicas mais
          próximas, confira as coberturas do seu plano, acesse a carteira digital, inclua dependentes e
          muito mais.
      </p>

      <div class="d-flex flex-row justify-content-start my-3">
          <img id="botao-play-store" class="icon-play-store mr-2" link-acesso="https://play.google.com/store/apps/details?id=clintech.clinapps.clincliente"/>
          <img id="botao-app-store" class="icon-app-store" link-acesso="https://apps.apple.com/br/app/clinapp-clientes/id1196293191"/>
      </div>
  </div>
</div>`

export const CONTEUDO_FOOTER_MELHORAI = `
  <div class="d-flex h-100 align-items-end beneficio-card-footer mt-3">
                  <div class="d-flex flex-column w-75 border-top">
                      <span class="titulo font-weight-bold mt-4">Agende sua consulta online</span>
                      <div class="d-flex flex-row my-4">
                          <div class="icon-lista-footer"></div>
                          <span class="font-weight-bold ml-2 subtitulo">Por telefone</span>
                      </div>
                      <p class="descricao">
                        Ligue para o (81) 3366-6810  e escolha o melhor dia e horário para a sua consulta.
                      </p>
                      <p>
                      No dia da consulta, você receberá por WhatsApp o link de acesso para entrar na consutla online. Basta acessá-lo e pronto!
                      </p>
                  </div>
              </div>`

export const CONTEUDO_FOOTER_MOBILE_MELHORAI = `<div class="d-flex h-100 align-items-end beneficio-card-footer mt-3">
  <div class="d-flex flex-column w-100">
      <span class="titulo font-weight-bold mt-1">Agende sua consulta online</span>
      <div class="d-flex flex-row my-4">
          <div class="icon-lista-footer"></div>
          <span class="font-weight-bold ml-2 subtitulo">Por telefone</span>
      </div>
      <p class="descricao">
        1. Ligue para o (81) 3366-6810  e escolha o melhor dia e horário para a sua consulta.
      </p>
      <p>
        2. No dia da consulta, você receberá por WhatsApp o link de acesso para entrar na consutla online. Basta acessá-lo e pronto!
      </p>
  </div>
</div>`

export const LINTREE_DOWNLOAD_CLINAPP = 'https://linktr.ee/ClinApp_Clientes'

export const NAO_INFORMADO = 'Não informado'

export const SEXO = {
	MASCULINO: 'Masculino',
	FEMININO: 'Feminino',
}

export const ASSUNTO_EMAIL_DEFAULT = 'Assinatura digital do contrato Clin - Benefícios + Tecnologia'
export const getCorpoEmailDefault = () => `
<p>Olá, tudo bem?</p>
<p>Seguem o preâmbulo (resumo do contrato) e o contrato Clin para a assinatura digital.</p>
<p>Assim que finalizar a assinatura, faça o envio dos seguintes documentos, para o vendedor que está acompanhando você:</p>
<ul>
   <li>Cópias do contrato social e cartão de CNPJ da empresa</li>
   <li>Cópias do RG, CPF e comprovante de endereço do responsável legal</li>
</ul>
`
