import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { BehaviorSubject } from 'rxjs'
import { CompromissoService } from 'src/app/service/compromisso.service'
import {
	TipoTitularInclusaoVidas
} from 'src/app/utils/enums'
import { InclusaoVidaNestDTO } from '../../model/cliente-compromisso/inclusao-vida-nest.dto'
import { Dicionario } from '../../model/dicionario'
import { Login } from '../../model/login'
import { ApiService } from '../../service/api.service'
import { DefaultSwalModalComponent } from '../default-swal-modal/default-swal-modal.component'
import { PlanoEmpresa } from '../../model/interfaces/inclusao-vida-popup'
import { InclusaoVidaAvulsaFormFotoComponent } from './components/inclusao-vida-avulsa-form-foto/inclusao-vida-avulsa-form-foto.component'
import { InclusaoVidaAvulsaFormPlanoComponent } from './components/inclusao-vida-avulsa-form-plano/inclusao-vida-avulsa-form-plano.component'
import { InclusaoVidaAvulsaFormVidaComponent } from './components/inclusao-vida-avulsa-form-vida/inclusao-vida-avulsa-form-vida.component'
import { InclusaoVidaAvulsaNovaInclusaoComponent } from './components/inclusao-vida-avulsa-nova-inclusao/inclusao-vida-avulsa-nova-inclusao.component'
import { InclusaoVidaPopupStepList, InclusaoVidaPopupSteps } from './inclusao-vida-popup-enum'
import { DadosInclusaoVidas, TitularSelect } from './model/dados-inclusao-vidas'
import { formatarData } from 'src/app/utils/util'
import { InclusaoVidaAvulsaFormVidaSegundaComponent } from './components/inclusao-vida-avulsa-form-vida-segunda/inclusao-vida-avulsa-form-vida-segunda.component'
import { patternCaracteresEspeciaiseEspaco } from 'src/app/model/vidas/vidas.enum'

@Component({
	selector: 'inclusao-vidas-avulsa-popup',
	templateUrl: './inclusao-vida-avulsa-popup.component.html',
	styleUrls: [
		'../../../vendor/libs/angular2-ladda/angular2-ladda.scss',
		'../../../vendor/libs/ng-select/ng-select.scss',
		'./inclusao-vida-avulsa-popup.component.scss',
		'../../../vendor/libs/spinkit/spinkit.scss',
		'../../../vendor/libs/ngb-datepicker/ngb-datepicker.scss'
	]
})
export class InclusaoVidaAvulsaPopupComponent implements OnInit, AfterViewChecked {

	steps = InclusaoVidaPopupStepList
	inclusaoVidaPopupStepEnum = InclusaoVidaPopupSteps
	initialSetp: InclusaoVidaPopupSteps
	currentStep = new BehaviorSubject<InclusaoVidaPopupSteps>(
		InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA
	)
	@ViewChild('swalVidaImplantadaComSucesso') swalVidaImplantadaComSucesso: DefaultSwalModalComponent
	@ViewChild('swalVidasNaoImplantadas') swalVidasNaoImplantadas: DefaultSwalModalComponent
	@ViewChild('inclusaoVidaAvulsaFormVida') inclusaoVidaAvulsaFormVida: InclusaoVidaAvulsaFormVidaComponent
	@ViewChild('inclusaoVidaAvulsaFormPlano') inclusaoVidaAvulsaFormPlano: InclusaoVidaAvulsaFormPlanoComponent
	@ViewChild('inclusaoVidaAvulsaFormFoto') inclusaoVidaAvulsaFormFoto: InclusaoVidaAvulsaFormFotoComponent
	@ViewChild('inclusaoVidaAvulsaNovaInclusao') inclusaoVidaAvulsaNovaInclusao: InclusaoVidaAvulsaNovaInclusaoComponent
	@ViewChild('inclusaoVidaAvulsaFormVidaSegunda') inclusaoVidaAvulsaFormVidaSegunda: InclusaoVidaAvulsaFormVidaSegundaComponent
	@ViewChild('inclusaoVidaAvulsaFormPlanoSegundo') inclusaoVidaAvulsaFormPlanoSegundo: InclusaoVidaAvulsaFormPlanoComponent
	formPrimeiraInclusao: FormGroup
	formSelecaoProdutos: FormGroup
	formSegundaInclusao: FormGroup
	formSegundaSelecaoProdutos: FormGroup
	desabilitarBotaoAvancar: () => {}

	user: Login
	loading: boolean = false
	tituloModal: string = ''
	labelAvancar: string = ''
	labelVoltar: string = ''
	removerVida: boolean = false
	addBeneficiary: InclusaoVidaNestDTO
	tipoVidaEnum = TipoTitularInclusaoVidas


	dadosInclusaoVidas: DadosInclusaoVidas = new DadosInclusaoVidas()

	constructor(private apiService: ApiService,
		private activeModal: NgbActiveModal,
		public compromissoService: CompromissoService,
		private cdr: ChangeDetectorRef) { }

	ngOnInit(): void {
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())
		this.apiService.getUser().then((res: Login) => {
			this.user = res
			this.verificarPermissaoExclusao()
		})
		this.verificarFluxoInclusao()
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}

	verificarPermissaoExclusao() {
		if (this.user.temPermissao(Dicionario.SALES_CLIENTE, 'D')) {
			this.removerVida = true
		}
	}

	avancarStep(step?: InclusaoVidaPopupSteps) {
		this.guardarDadosStepAtual()
		if (!step) {
			this.currentStep.next(this.currentStep.getValue() + 1)
		} else {
			this.currentStep.next(step)
		}
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())
	}

	voltarStep() {
		this.guardarDadosStepAtual()
		this.currentStep.next(this.currentStep.getValue() - 1)
		this.configurarLabelsEValidacaoStep(this.currentStep.getValue())
	}

	guardarDadosStepAtual() {
		this.formPrimeiraInclusao = this.formPrimeiraInclusao || this.inclusaoVidaAvulsaFormVida?.formPrimeiraInclusao
		this.formSelecaoProdutos = this.formSelecaoProdutos || this.inclusaoVidaAvulsaFormPlano?.formSelecaoProdutos
		this.formSegundaInclusao = this.formSegundaInclusao || this.inclusaoVidaAvulsaFormVidaSegunda?.formSegundaInclusao
		this.formSegundaSelecaoProdutos = this.formSegundaSelecaoProdutos || this.inclusaoVidaAvulsaFormPlanoSegundo?.formSelecaoProdutos
		this.dadosInclusaoVidas.idEmpresa = this.inclusaoVidaAvulsaFormVida?.dadosInclusaoVidas.idEmpresa || this.dadosInclusaoVidas.idEmpresa
		this.dadosInclusaoVidas.idEmpresaS4e = this.inclusaoVidaAvulsaFormVida?.dadosInclusaoVidas.idEmpresaS4e || this.dadosInclusaoVidas.idEmpresaS4e
		this.dadosInclusaoVidas.nomeEmpresa = this.inclusaoVidaAvulsaFormVida?.dadosInclusaoVidas.nomeEmpresa || this.dadosInclusaoVidas.nomeEmpresa
		this.dadosInclusaoVidas.idCompromisso = this.inclusaoVidaAvulsaFormVida?.dadosInclusaoVidas.idCompromisso || this.dadosInclusaoVidas.idCompromisso
		this.dadosInclusaoVidas.cpfTitular = this.inclusaoVidaAvulsaFormVida?.dadosInclusaoVidas.cpfTitular || this.dadosInclusaoVidas.cpfTitular
		this.dadosInclusaoVidas.arquivosEnviados = this.inclusaoVidaAvulsaFormFoto?.arquivosEnviados || this.dadosInclusaoVidas.arquivosEnviados
		this.dadosInclusaoVidas.urlFicha = this.inclusaoVidaAvulsaFormFoto?.urlFicha || this.dadosInclusaoVidas.urlFicha
	}

	configurarLabelsEValidacaoStep(step: InclusaoVidaPopupSteps) {
		this.tituloModal = 'Incluir vida avulsa'
		this.labelAvancar = 'Avançar'
		this.labelVoltar = 'Voltar'
		switch (step) {
			case InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA:
				this.desabilitarBotaoAvancar = () =>
					(this.inclusaoVidaAvulsaFormVida?.formPrimeiraInclusao?.invalid
						&& !this.inclusaoVidaAvulsaFormVida?.beneficiarioJaCadastrado) ||
					this.inclusaoVidaAvulsaFormVida?.buscandoCPF ||
					this.inclusaoVidaAvulsaFormVida?.buscandoEmpresa ||
					!this.inclusaoVidaAvulsaFormVida?.dadosInclusaoVidas.idEmpresa
				break

			case InclusaoVidaPopupSteps.STEP_SELECAO_PRODUTO:
				this.desabilitarBotaoAvancar = () => this.inclusaoVidaAvulsaFormPlano?.formSelecaoProdutos?.invalid
				break

			case InclusaoVidaPopupSteps.STEP_ENVIO_FOTO:
				this.labelAvancar = 'Finalizar inclusão'
				const nomeVida = this.formSegundaInclusao?.get('vida_nome')?.value || this.formPrimeiraInclusao?.get('vida_nome')?.value
				this.dadosInclusaoVidas.nomeArquivo = `${this.dadosInclusaoVidas.idCompromisso}-${nomeVida}`
				this.desabilitarBotaoAvancar = () =>
					!this.inclusaoVidaAvulsaFormFoto?.arquivosEnviados?.length || !this.inclusaoVidaAvulsaFormFoto?.urlFicha
				break

			case InclusaoVidaPopupSteps.STEP_FORM_NOVA_INCLUSAO_VIDA:
				this.desabilitarBotaoAvancar = () =>
					(this.inclusaoVidaAvulsaFormVidaSegunda?.formSegundaInclusao?.invalid
						&& !this.inclusaoVidaAvulsaFormVidaSegunda?.beneficiarioJaCadastrado) ||
					this.inclusaoVidaAvulsaFormVidaSegunda?.buscandoCPF ||
					this.inclusaoVidaAvulsaFormVidaSegunda?.buscandoEmpresa ||
					!this.inclusaoVidaAvulsaFormVidaSegunda?.dadosInclusaoVidas.idEmpresa
				break
				
			case InclusaoVidaPopupSteps.STEP_NOVA_SELECAO_PRODUTO:
				this.tituloModal = 'Selecione um plano de cada produto'
				this.labelAvancar = 'Finalizar inclusão'
				this.desabilitarBotaoAvancar = () => this.inclusaoVidaAvulsaFormPlanoSegundo?.formSelecaoProdutos?.invalid || this.loading
				break
		}
	}

	dismiss() {
		this.activeModal.dismiss()
	}

	removeExtraSpaces(beneficiaryData = {}, listToFix: string[] = []) {
		let dataFixed = {}
		listToFix.map(key => {
			if (beneficiaryData[key]) {
				dataFixed[key] = beneficiaryData[key].replace(/ {2,}/g, ' ').trim()
			}
		})
		return dataFixed
	}

	async salvarInclusaoVida() {
		this.guardarDadosStepAtual()
		this.loading = true
		let valorPlano = 0

		let formInclusaoVida: FormGroup
		let formSelecaoProdutos: FormGroup
		if (this.currentStep.getValue() === InclusaoVidaPopupSteps.STEP_ENVIO_FOTO) {
			formInclusaoVida = this.formPrimeiraInclusao
			formSelecaoProdutos = this.formSelecaoProdutos
		} else {
			formInclusaoVida = this.formSegundaInclusao
			formSelecaoProdutos = this.inclusaoVidaAvulsaFormPlanoSegundo?.formSelecaoProdutos
		}

		formInclusaoVida.get('cli_cpf_titular').setValue(this.dadosInclusaoVidas.cpfTitular)
		if (formSelecaoProdutos) {
			const idProdutoSelecionado = formSelecaoProdutos.get('cco_id_produto_s4')?.value
			if (idProdutoSelecionado) {
				const produtoSelecionado: any = this.dadosInclusaoVidas.listaProdutos.find(
					(produto: any) => produto.idPlano === Number(idProdutoSelecionado)
				)
				const grauParentescoSelecionado = formInclusaoVida.get('cli_grauparentesco')?.value
				if (grauParentescoSelecionado.value === TipoTitularInclusaoVidas.TITULAR) {
					valorPlano = produtoSelecionado.valorTitular
				} else {
					valorPlano = produtoSelecionado.valorDependente
				}
			}
		}

		const {
			vida_tipo, vida_nome, vida_cpf, vida_nomemae, vida_nascimento, vida_sexo, vida_telefone, vida_email, vida_idcompromisso,
			vida_matricula, cli_grauparentesco, fk_idtitular, cli_codigoassociados4e, cli_cpf_titular
		} = formInclusaoVida.getRawValue()

		const listToFix = ['cli_nome', 'cli_nomemae', 'cli_matricula', 'cli_email']
		const { cco_id_produto_s4 } = formSelecaoProdutos.getRawValue()

		this.addBeneficiary = {
			cli_nome: vida_nome,
			cli_cpf: vida_cpf.replace(patternCaracteresEspeciaiseEspaco, ''),
			cli_nomemae: vida_nomemae,
			cli_datanascimento: formatarData(vida_nascimento),
			cli_sexo: vida_sexo.value,
			cli_telefonecelular: vida_telefone.replace(patternCaracteresEspeciaiseEspaco, ''),
			cli_email: vida_email,
			cli_grauparentesco: cli_grauparentesco.value,
			cli_matricula: vida_matricula,
			cli_idplano: cco_id_produto_s4,
			fk_idtitular,
			cli_tipo: vida_tipo.value,
			cli_status: 'A',
			cli_titular: vida_tipo.descricao === 'Titular',
			fk_idempresa: this.dadosInclusaoVidas.idEmpresa,
			cli_enviar_para_s4E: true,
			cli_cod_empresa_s4E: this.dadosInclusaoVidas.idEmpresaS4e,
			cli_codigoassociados4e: cli_codigoassociados4e ? cli_codigoassociados4e.value : null,
			cli_cpf_titular,
			clienteCompromisso: {
				fk_idprodutoempresa: null, cco_id_produto_s4, fk_idcompromisso: vida_idcompromisso, cco_valor_produto: valorPlano
			},
			cli_anexos: this.dadosInclusaoVidas.urlFicha
		}
		const beneficiaryFixed = this.removeExtraSpaces(this.addBeneficiary, listToFix)
		this.addBeneficiary = { ...this.addBeneficiary, ...beneficiaryFixed }

		try {
			const { cliente, clienteS4E } = await this.compromissoService.inserirVidaNestNovo(this.addBeneficiary)

			if (!cliente) {
				this.apiService.showToast(
					null,
					'Ocorreu um erro inesperado, entre em contato com o administrador',
					'error')
				return
			}

			this.dadosInclusaoVidas.tipoVida = vida_tipo?.value || this.dadosInclusaoVidas.tipoVida
			if (vida_tipo?.value === this.tipoVidaEnum.TITULAR) {
				this.dadosInclusaoVidas.nomeTitular = vida_nome
				this.dadosInclusaoVidas.idTitular = cliente.pk_idcliente
			}
			if (clienteS4E) {
				const { titular, dependentes } = clienteS4E
				this.dadosInclusaoVidas.codAssociadoS4e = titular?.associado?.id || dependentes[0]?.idAssociado || this.dadosInclusaoVidas.codAssociadoS4e
				this.swalVidaImplantadaComSucesso.openModal()
			} else {
				this.swalVidasNaoImplantadas.openModal()
			}

		} catch (error) {
			this.apiService.showToast(
				null,
				'Ocorreu um erro inesperado, entre em contato com o administrador',
				'error')
		} finally { this.loading = false }
	}

	async verificarFluxoInclusao() {
		if (this.initialSetp == InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA
			&& this.dadosInclusaoVidas.tipoVida === TipoTitularInclusaoVidas.DEPENDENTE) {
			this.tituloModal = 'Incluir dependente'
			this.currentStep.next(InclusaoVidaPopupSteps.STEP_FORM_INCLUSAO_VIDA)
		} else if (this.dadosInclusaoVidas.tipoVida === TipoTitularInclusaoVidas.TITULAR ||
			this.dadosInclusaoVidas.tipoVida === TipoTitularInclusaoVidas.DEPENDENTE) {
			this.currentStep.next(InclusaoVidaPopupSteps.STEP_NOVA_INCLUSAO)
		}
	}

	finalizarInclusao() {
		this.activeModal.close(this.addBeneficiary)
	}

	novaInclusaoDependente() {
		this.dadosInclusaoVidas.tipoVida = TipoTitularInclusaoVidas.DEPENDENTE
		this.avancarStep(InclusaoVidaPopupSteps.STEP_FORM_NOVA_INCLUSAO_VIDA)
	}

	fecharSwalVidaImplantadaComSucessoOuNao() {
		if (this.dadosInclusaoVidas.tipoVida == TipoTitularInclusaoVidas.DEPENDENTE) {
			this.finalizarInclusao()
		} else if (this.currentStep.getValue() === InclusaoVidaPopupSteps.STEP_NOVA_SELECAO_PRODUTO) {
			this.avancarStep(InclusaoVidaPopupSteps.STEP_NOVA_INCLUSAO)
		} else {
			this.avancarStep()
		}
	}

	CarregarPlanoFromFormVidas(listaProdutos: PlanoEmpresa[]) {
		this.dadosInclusaoVidas.listaProdutos = listaProdutos
	}
}
