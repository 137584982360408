import {NgModule} from '@angular/core'
import {NumericInputDirective} from './directives/only-number-input-directive'
// import ngx-translate and the http loader
// https://github.com/ngx-translate/core
//
// Esse resolveu o translate: -> https://medium.com/@lopesgon/translate-angular-4-with-ngx-translate-and-multiple-modules-7d9f0252f139
// https://stackblitz.com/github/ngx-translate/example?file=src%2Fapp%2Fapp.component.ts
import {TranslateModule} from '@ngx-translate/core'

// https://github.com/KingMario/packages/tree/master/projects/ngx-upper-case-directive
// import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive';
// https://github.com/KingMario/packages/tree/master/projects/ngx-lower-case-directive
// import { NgxLowerCaseDirectiveModule } from 'ngx-lower-case-directive';
// https://github.com/KingMario/packages/tree/master/projects/ngx-trim-directive
// import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import {TagInputModule} from 'ngx-chips'
// https://github.com/ng-select/ng-select
import {NgSelectModule} from '@ng-select/ng-select'

import {LaddaModule} from 'angular2-ladda'

import {FormsModule, ReactiveFormsModule} from '@angular/forms'

import {CommonModule} from '@angular/common'
import {HttpClientModule} from '@angular/common/http'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap'
import {BlockUIModule} from 'ng-block-ui'
import {InfiniteScrollModule} from 'ngx-infinite-scroll'
import {NgxLowerCaseDirectiveModule} from 'ngx-lower-case-directive'
import {NgxUpperCaseDirectiveModule} from 'ngx-upper-case-directive'
import {AuditViewComponent} from './audit/audit-view/audit-view.component'
import {DateMaskDirective} from './directives/date-mask-directive'
import {DisableControlDirective} from './directives/disable-control-directive '
import {NumericDirective} from './directives/numeric-directive'
import {OnlyNumberDirective} from './directives/only-number-directive'
import {TimeMaskDirective} from './directives/time-mask-directive'
import {EmailPopupComponent} from './others-component/email-popup/email-popup.component'
import {ProgressPopupComponent} from './others-component/progress-popup/progress-popup.component'

import {RouterModule} from '@angular/router'
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2'
import {CalendarModule} from 'angular-calendar'
import {DropzoneModule} from 'ngx-dropzone-wrapper'
import {ImageCropperModule} from 'ngx-image-cropper'
import {NgxImageGalleryModule} from 'ngx-image-gallery'
import {IConfig, NgxMaskModule} from 'ngx-mask'
import {QuillModule} from 'ngx-quill'
import {TimeagoModule} from 'ngx-timeago'
import {WebcamModule} from 'ngx-webcam'
import {DragNDropDirective} from './directives/drag-n-drop.directive'
import {TrackCapsDirective} from './directives/TrackCapsDirective'
import {ItemAbaAtividadesComponent} from './others-component/abas/item-aba/item-aba-atividades/item-aba-atividades.component'
import {ItemAbaContratoComponent} from './others-component/abas/item-aba/item-aba-contrato/item-aba-contrato.component'
import {ItemAbaDadosDeConversaoComponent} from './others-component/abas/item-aba/item-aba-dados-de-conversao/item-aba-dados-de-conversao.component'
import {ItemAbaHistoricoComponent} from './others-component/abas/item-aba/item-aba-historico/item-aba-historico.component'
import {ItemAbaPropostaComponent} from './others-component/abas/item-aba/item-aba-proposta/item-aba-proposta.component'
import {ItemAbaVisaoGeralComponent} from './others-component/abas/item-aba/item-aba-visao-geral/item-aba-visao-geral.component'
import {AcordeonComponent} from './others-component/acordeon/acordeon.component'
import {AgisalesTextAreaComponent} from './others-component/agisales-text-area/agisales-text-area.component'
import {AlertCardComponent} from './others-component/alert-card/alert-card.component'
import {ArquivosNecessariosComponent} from './others-component/arquivos-necessarios/arquivos-necessarios.component'
import {BadgeComponent} from './others-component/badge/badge.component'
import {AgibenBannerAlertComponent} from './others-component/banners/agiben-banner-alert/agiben-banner-alert.component'
import {AgibenDefaultBreadcrumbsComponent} from './others-component/breadcrumbs/agiben-default-breadcrumbs/agiben-default-breadcrumb.component'
import {CctActStepsBreadcrumbComponent} from './others-component/breadcrumbs/cct-act-steps-breadcrumb/cct-act-steps-breadcrumb.component'
import {AgiButtonComponent} from './others-component/buttons/agi-button/agi-button.component'
import {AgibenKpiCardComponent} from './others-component/cards/card-kpi/agiben-kpi-card/agiben-kpi-card.component'
import {CardResumoTaxasComponent} from './others-component/cards/card-resumo-taxas/card-resumo-taxas.component'
import {IconFileCardComponent} from './others-component/cards/icon-file-card/icon-file-card.component'
import {ProdutosSelecionadosComponent} from './others-component/cards/produtos-selecionados/produtos-selecionados.component'
import {SelecaoProdutoCardComponent} from './others-component/cards/selecao-produto-card/selecao-produto-card.component'
import {ConclusaoPopupComponent} from './others-component/conclusao-popup/conclusao-popup.component'
import {AgisalesDatepickerComponent} from './others-component/datepicker/agisales-datepicker/agisales-datepicker.component'
import {DefaultButtonComponent} from './others-component/default-button/default-button.component'
import {DefaultSwalModalComponent} from './others-component/default-swal-modal/default-swal-modal.component'
import {EditCardHeaderComponent} from './others-component/edit-card-header/edit-card-header.component'
import {EditCardComponent} from './others-component/edit-card/edit-card.component'
import {EditorQuillComponent} from './others-component/editor-quill/editor-quill.component'
import {GenericBannerComponent} from './others-component/generic-banner/generic-banner.component'
import {HeaderUserComponent} from './others-component/header-user/header-user.component'
import {InclusaoVidasArquivosPopup} from './others-component/inclusao-vidas-arquivos-popup/inclusao-vidas-arquivos-popup.component'
import {InclusaoVidasSelecaoPopup} from './others-component/inclusao-vidas-selecao-popup/inclusao-vidas-selecao-popup.component'
import {InformarObservacaoPopupComponent} from './others-component/informar-observacao-popup/informar-observacao-popup.component'
import {InputComponent} from './others-component/input/input.component'
import {ListagemDeArquivosEmpresaComponent} from './others-component/listagem-de-arquivos-empresa/listagem-de-arquivos-empresa.component'
import {ListagemHistoricoEmpresaComponent} from './others-component/listagem-historico-empresa/listagem-historico-empresa.component'
import {ModalGenericoComponent} from './others-component/modal-generico/modal-generico.component'
import {ModalWithOneButtonComponent} from './others-component/modal-with-one-button/modal-with-one-button.component'
import {MultiToggleButtonsComponent} from './others-component/multi-toggle-buttons/multi-toggle-buttons.component'
import {PesquisarComponent} from './others-component/pesquisar/pesquisar.component'
import {AtividadeAdmPopupComponent} from './others-component/popups/atividade-adm-popup/atividade-adm-popup.component'
import {ModalGrupoCctComponent} from './others-component/popups/modal-grupo-cct/modal-grupo-cct.component'
import {RadioGroupComponent} from './others-component/radio-group/radio-group.component'
import {CheckboxSelectComponent} from './others-component/selects/checkbox-select/checkbox-select.component'
import {SimpleSelectComponent} from './others-component/selects/simple-select/simple-select.component'
import {AgiStepperModule} from './others-component/stepper/agi-stepper.module'
import {DefaultTabsModule} from './others-component/tabs/default-tabs/default-tabs.module'
import {UploadBoxDragNDropComponent} from './others-component/upload-box-drag-n-drop/upload-box-drag-n-drop.component'
import {UploadPopupComponent} from './others-component/upload-popup/upload-popup.component'
import {UserNameCircleComponent} from './others-component/user-name-circle/user-name-circle.component'
import {UsuarioViewComponent} from './others-component/usuario-view-component/usuario-view-component'
import {VisualizarCampanhaPopupComponent} from './others-component/visualizar-campanha-popup/visualizar-campanha-popup.component'
import {SimNaoPipe} from './pipes/booleanoBr.pipe'
import {DataIsoToPtbr} from './pipes/dataIsoToPtbr.pipe'
import {DataPtBrComHoraPipe} from './pipes/dataPtBrComHora.pipe'
import {FormatarBytesPipe} from './pipes/formatarBytes.pipe'
import {NomesMaiusculosPipe} from './pipes/nomesMaiusculos.pipe'
import {NumeroCompromisso} from './pipes/numeroCompromisso.pipe'
import {splitVariaveisCamelCasePipe} from './pipes/splitVariaveisCamelCase.pipe'
import {PhonePipe} from './pipes/telefoneBr.pipe'
import {TipoAtividadePipe} from './pipes/tipoAtividade.pipe'
import {TipoCargoPipe} from './pipes/tipoCargo.pipe'
import {TipoStatusAtividade} from './pipes/tipoStatusAtividade.pipe'
import {TipoStatusCompromisso} from './pipes/tipoStatusCompromisso.pipe'
import {TipoStatusPropostaPipe} from './pipes/tipoStatusProposta.pipe'
import {TipoStatusVida} from './pipes/tipoStatusVida.pipe'
import {TipoClientePipe} from './pipes/tipoTitular.pipe'

import { AgibenSimpleTooltipComponent } from './others-component/agiben-simple-tooltip/agiben-simple-tooltip.component'
import { RadioGroupV2Component } from './others-component/radio-group-v2/radio-group-v2.component'
import { CheckboxSelectV2Component } from './others-component/selects/checkbox-select-v2/checkbox-select-v2.component'
import { AgisalesInputV2Component } from './others-component/agisales-input-v2/agisales-input-v2.component'
import { SimpleSelectV2Component } from './others-component/selects/simple-select-v2/simple-select-v2.component'
import { AcordeonV2Component } from './others-component/acordeon-v2/acordeon-v2.component'
import { ListagemDeArquivosEnvioComponent } from './others-component/listagem-de-arquivos-envio/listagem-de-arquivos-envio.component'
import { AgibenPaginacaoTabelaComponent } from './others-component/pagination/agiben-paginacao-tabela/agiben-paginacao-tabela.component'
import { MigrarPlanoPopupComponent } from './others-component/migrar-plano-popup/migrar-plano-popup.component'
import { InclusaoVidaAvulsaPopupComponent } from './others-component/inclusao-vida-avulsa-popup/inclusao-vida-avulsa-popup.component'
import { MigrarPlanoFormVidaComponent } from './others-component/migrar-plano-popup/components/migrar-plano-form-vida/migrar-plano-form-vida.component'
import { MigrarPlanoFormPlanoComponent } from './others-component/migrar-plano-popup/components/migrar-plano-form-plano/migrar-plano-form-plano.component'
import { MigrarPlanoFormFotoComponent } from './others-component/migrar-plano-popup/components/migrar-plano-form-foto/migrar-plano-form-foto.component'
import { InclusaoVidaAvulsaFormVidaComponent } from './others-component/inclusao-vida-avulsa-popup/components/inclusao-vida-avulsa-form-vida/inclusao-vida-avulsa-form-vida.component'
import { InclusaoVidaAvulsaFormPlanoComponent } from './others-component/inclusao-vida-avulsa-popup/components/inclusao-vida-avulsa-form-plano/inclusao-vida-avulsa-form-plano.component'
import { InclusaoVidaAvulsaFormFotoComponent } from './others-component/inclusao-vida-avulsa-popup/components/inclusao-vida-avulsa-form-foto/inclusao-vida-avulsa-form-foto.component'
import { InclusaoVidaAvulsaNovaInclusaoComponent } from './others-component/inclusao-vida-avulsa-popup/components/inclusao-vida-avulsa-nova-inclusao/inclusao-vida-avulsa-nova-inclusao.component'
import { InclusaoVidaAvulsaFormVidaSegundaComponent } from './others-component/inclusao-vida-avulsa-popup/components/inclusao-vida-avulsa-form-vida-segunda/inclusao-vida-avulsa-form-vida-segunda.component'
import { AgisalesTextAreaV2Component } from './others-component/agisales-text-area-v2/agisales-text-area-v2.component'
import { AgibenIntervalosDatasComponent } from './others-component/agiben-intervalos-datas/agiben-intervalos-datas.component'
import { CalendarioV2Component } from './others-component/calendario-v2/calendario-v2.component'
import { HeaderModalContentComponent } from './others-component/header-modal-title/header-modal-content.component'
import { ListagemSignatariosComponent } from './others-component/listagem-signatarios/listagem-signatarios.component'
import { StatusContratacaoComponent } from './others-component/status-contratacao/status-contratacao.component'
import { DataPtBrSemHoraPipe } from './pipes/dataPtBrSemHora.pipe'
import { DefaultHeaderTitleComponent } from './others-component/default-title/default-header-title/default-header-title.component'
import {AgibenBannerComponent} from './others-component/banners/agiben-banner/agiben-banner.component'

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null
const SHARED_MODULE = [
	TranslateModule,
	NgbModule,
	CommonModule,
	HttpClientModule,
	NgbModule,
	InfiniteScrollModule,
	FormsModule,
	ReactiveFormsModule,
	// https://github.com/ng-select/ng-select
	NgSelectModule,
	TagInputModule,
	LaddaModule,
	NgxImageGalleryModule,
	SweetAlert2Module,
	BlockUIModule,
	TimeagoModule,
	DropzoneModule,
	ImageCropperModule,
	WebcamModule,
	NgxMaskModule,
	DefaultTabsModule,
	CalendarModule,
	RouterModule,
	AgiStepperModule,
	QuillModule,
]

@NgModule({
	declarations: [
		BadgeComponent,
		AtividadeAdmPopupComponent,
		NumericDirective,
		OnlyNumberDirective,
		NumericInputDirective,
		DisableControlDirective,
		DateMaskDirective,
		TimeMaskDirective,
		ProgressPopupComponent,
		UploadPopupComponent,
		AuditViewComponent, // Add modal component to "declarations"
		EmailPopupComponent, // Add modal component to "declarations"
		VisualizarCampanhaPopupComponent,
		TrackCapsDirective,
		UsuarioViewComponent, // Add modal component to "declarations"
		TipoAtividadePipe,
		TipoCargoPipe,
		TipoStatusAtividade,
		DataIsoToPtbr,
		NumeroCompromisso,
		TipoStatusCompromisso,
		ConclusaoPopupComponent,
		InclusaoVidasSelecaoPopup,
		InclusaoVidasArquivosPopup,
		PhonePipe,
		SimNaoPipe,
		TipoClientePipe,
		TipoStatusVida,
		NomesMaiusculosPipe,
		ItemAbaAtividadesComponent,
		DataPtBrComHoraPipe,
		DataPtBrSemHoraPipe,
		ItemAbaHistoricoComponent,
		ItemAbaVisaoGeralComponent,
		ItemAbaDadosDeConversaoComponent,
		ItemAbaPropostaComponent,
		ItemAbaContratoComponent,
		SelecaoProdutoCardComponent,
		ListagemDeArquivosEmpresaComponent,
		StatusContratacaoComponent,
		ListagemDeArquivosEnvioComponent,
		ListagemSignatariosComponent,
		splitVariaveisCamelCasePipe,
		IconFileCardComponent,
		TipoStatusPropostaPipe,
		FormatarBytesPipe,
		ListagemHistoricoEmpresaComponent,
		InformarObservacaoPopupComponent,
		ProdutosSelecionadosComponent,
		MultiToggleButtonsComponent,
		DefaultButtonComponent,
		ModalWithOneButtonComponent,
		ModalGrupoCctComponent,
		EditCardHeaderComponent,
		EditCardComponent,
		PesquisarComponent,
		SimpleSelectComponent,
		CheckboxSelectComponent,
		InputComponent,
		RadioGroupComponent,
		HeaderUserComponent,
		AlertCardComponent,
		CctActStepsBreadcrumbComponent,
		AgisalesDatepickerComponent,
		AgisalesTextAreaComponent,
		DefaultSwalModalComponent,
		UserNameCircleComponent,
		ArquivosNecessariosComponent,
		ModalGenericoComponent,
		DefaultHeaderTitleComponent,
		CardResumoTaxasComponent,
		AcordeonComponent,
		AcordeonV2Component,
		GenericBannerComponent,
		DragNDropDirective,
		UploadBoxDragNDropComponent,
		AgiButtonComponent,
		AgibenDefaultBreadcrumbsComponent,
		EditorQuillComponent,
		AgibenBannerAlertComponent,
		AgibenKpiCardComponent,
		RadioGroupV2Component,
		AgibenSimpleTooltipComponent,
		AgisalesInputV2Component,
		CheckboxSelectV2Component,
		SimpleSelectV2Component,
		MigrarPlanoPopupComponent,
		MigrarPlanoFormVidaComponent,
		MigrarPlanoFormPlanoComponent,
		MigrarPlanoFormFotoComponent,
		InclusaoVidaAvulsaPopupComponent,
		InclusaoVidaAvulsaFormVidaComponent,
		InclusaoVidaAvulsaFormPlanoComponent,
		InclusaoVidaAvulsaFormFotoComponent,
		InclusaoVidaAvulsaNovaInclusaoComponent,
		InclusaoVidaAvulsaFormVidaSegundaComponent,
		AgibenBannerComponent,
		CalendarioV2Component,
		AgisalesTextAreaV2Component,
		AgibenPaginacaoTabelaComponent,
		AgibenIntervalosDatasComponent,
		HeaderModalContentComponent,
	],
	exports: [
		BadgeComponent,
		...SHARED_MODULE,
		// NgxUpperCaseDirectiveModule,
		// NgxLowerCaseDirectiveModule,
		// NgxTrimDirectiveModule,
		TranslateModule,
		NgxUpperCaseDirectiveModule,
		NgxLowerCaseDirectiveModule,
		NumericDirective,
		OnlyNumberDirective,
		NumericInputDirective,
		DisableControlDirective,
		DateMaskDirective,
		TimeMaskDirective,
		TrackCapsDirective,
		ProgressPopupComponent,
		VisualizarCampanhaPopupComponent,
		AuditViewComponent, // Add modal component to "declarations"
		EmailPopupComponent, // Add modal component to "declarations"
		UsuarioViewComponent, // Add modal component to "declarations"
		TipoAtividadePipe,
		TipoCargoPipe,
		TipoStatusAtividade,
		DataIsoToPtbr,
		NumeroCompromisso,
		TipoStatusCompromisso,
		PhonePipe,
		SimNaoPipe,
		TipoClientePipe,
		TipoStatusVida,
		NomesMaiusculosPipe,
		ItemAbaAtividadesComponent,
		DataPtBrComHoraPipe,
		DataPtBrSemHoraPipe,
		ItemAbaHistoricoComponent,
		ItemAbaVisaoGeralComponent,
		ItemAbaDadosDeConversaoComponent,
		ItemAbaPropostaComponent,
		ItemAbaContratoComponent,
		SelecaoProdutoCardComponent,
		StatusContratacaoComponent,
		ListagemDeArquivosEmpresaComponent,
		ListagemDeArquivosEnvioComponent,
		ListagemSignatariosComponent,
		splitVariaveisCamelCasePipe,
		IconFileCardComponent,
		TipoStatusPropostaPipe,
		FormatarBytesPipe,
		ListagemHistoricoEmpresaComponent,
		InformarObservacaoPopupComponent,
		ProdutosSelecionadosComponent,
		MultiToggleButtonsComponent,
		EditCardHeaderComponent,
		EditCardComponent,
		PesquisarComponent,
		SimpleSelectComponent,
		CheckboxSelectComponent,
		InputComponent,
		RadioGroupComponent,
		AlertCardComponent,
		DefaultSwalModalComponent,
		HeaderUserComponent,
		AlertCardComponent,
		CctActStepsBreadcrumbComponent,
		DefaultButtonComponent,
		AgisalesDatepickerComponent,
		AgisalesTextAreaComponent,
		UserNameCircleComponent,
		ArquivosNecessariosComponent,
		ModalGenericoComponent,
		CardResumoTaxasComponent,
		AcordeonComponent,
		AcordeonV2Component,
		GenericBannerComponent,
		DragNDropDirective,
		UploadBoxDragNDropComponent,
		AgiButtonComponent,
		AgibenDefaultBreadcrumbsComponent,
		EditorQuillComponent,
		AgibenBannerAlertComponent,
		AgibenKpiCardComponent,
		RadioGroupV2Component,
		AgibenSimpleTooltipComponent,
		AgisalesInputV2Component,
		CheckboxSelectV2Component,
		SimpleSelectV2Component,
		AgisalesTextAreaV2Component,
		AgibenBannerComponent,
		HeaderModalContentComponent,
		AgibenPaginacaoTabelaComponent,
		AgibenIntervalosDatasComponent,
		CalendarioV2Component,
	],
	entryComponents: [
		InclusaoVidasSelecaoPopup,
		InclusaoVidasArquivosPopup,
		ConclusaoPopupComponent,
		ModalWithOneButtonComponent,
		ModalGrupoCctComponent,
		BadgeComponent,
		ModalGenericoComponent,
		UploadPopupComponent,		
		MigrarPlanoPopupComponent,
		InclusaoVidaAvulsaPopupComponent,
	],
	imports: SHARED_MODULE,
})
export class SharedModule {}
