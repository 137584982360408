import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { ValidateBrService } from 'angular-validate-br'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { DefaultSwalModalComponent } from 'src/app/others-component/default-swal-modal/default-swal-modal.component'
import { ApiService } from 'src/app/service/api.service'
import { CompromissoService } from 'src/app/service/compromisso.service'
import { TipoTitularInclusaoVidas, TipoValidoOuInvalido } from 'src/app/utils/enums'
import { retornarMsgErroPorStatus } from 'src/app/utils/util'
import { OpcoesGrauParentesco, OpcoesTipoSexo, OpcoesTipoVida, patternCaracteresEspeciaiseEspaco } from 'src/app/model/vidas/vidas.enum'
import { DadosInclusaoVidas, EmpresaSelect, TipoVidaSelect, TitularSelect } from '../../model/dados-inclusao-vidas'
import { PlanoEmpresa } from 'src/app/model/interfaces/inclusao-vida-popup'

@Component({
	selector: 'app-inclusao-vida-avulsa-form-vida',
	templateUrl: './inclusao-vida-avulsa-form-vida.component.html',
	styleUrls: ['./inclusao-vida-avulsa-form-vida.component.scss'],
})
export class InclusaoVidaAvulsaFormVidaComponent implements OnInit, AfterViewInit {

	opcoesTipoVida = OpcoesTipoVida
	opcoesTipoSexo = OpcoesTipoSexo
	opcoesGrauParentesco = OpcoesGrauParentesco

	opcoesTitulares: []
	opcoesEmpresa: EmpresaSelect[]
	tipoVidaEnum = TipoTitularInclusaoVidas
	exigeMatricula: boolean = false

	dataNascimentoMinima: Date = new Date(1900, 0, 1)
	buscandoCPF: boolean = false
	buscandoEmpresa: boolean = false
	buscandoColaboradoresTitulares: boolean = false
	beneficiarioJaCadastrado: boolean = false
	isEmpresaNaoEncontrada: boolean = false

	@Input() dadosInclusaoVidas: DadosInclusaoVidas

	@ViewChild('swalBeneficiarioJaCadastrado') swalBeneficiarioJaCadastrado: DefaultSwalModalComponent
	@Output() onCarregarPlanoFromFormVidas = new EventEmitter<PlanoEmpresa[]>();
	@Input() formPrimeiraInclusao: FormGroup

	get getFormPrimeiraInclusao() {
		return this.formPrimeiraInclusao.controls
	}

	constructor(
		private compromissoService: CompromissoService,
		private validateBrService: ValidateBrService,
		public translateService: TranslateService,
		private apiService: ApiService,
	) { }

	ngOnInit() {
		if (!this.formPrimeiraInclusao) {
			this.formPrimeiraInclusao = new FormGroup({
				vida_tipo: new FormControl(null, Validators.required),
				vida_empresa: new FormControl(
					null,
					this.dadosInclusaoVidas.tipoVida === TipoTitularInclusaoVidas.TITULAR ? [Validators.required] : []
				),
				vida_nome: new FormControl(null, [Validators.required, Validators.minLength(10)]),
				vida_cpf: new FormControl(null, [Validators.required, this.validateBrService.cpf]),
				vida_nomemae: new FormControl(null, [Validators.required]),
				vida_nascimento: new FormControl(null, [Validators.required]),
				vida_sexo: new FormControl(null, [Validators.required]),
				vida_telefone: new FormControl(null, [Validators.required]),
				vida_email: new FormControl(null, [Validators.email]),
				vida_idcompromisso: new FormControl(null, [Validators.required]),
				vida_matricula: new FormControl(null),
				cli_grauparentesco: new FormControl(this.opcoesTipoVida[0], [Validators.required]),
				cli_codigoassociados4e: new FormControl(null),
				cli_empresa: new FormControl(null),
				fk_idtitular: new FormControl(null),
				cli_cpf_titular: new FormControl(null)
			})
			this.verificarFluxoInclusao()
		}
		if (!this.dadosInclusaoVidas.idEmpresa)
			this.formPrimeiraInclusao.get('vida_cpf')?.disable()
	}

	async verificarFluxoInclusao() {
		if (this.dadosInclusaoVidas.codAssociadoS4e
			&& this.dadosInclusaoVidas.idTitular
			&& this.dadosInclusaoVidas.titularVinculado
			&& this.dadosInclusaoVidas.idEmpresaS4e
			&& this.dadosInclusaoVidas.idEmpresa) {
			this.formPrimeiraInclusao.disable()
			await this.listarBeneficiarioPorNomeOuCpf(this.dadosInclusaoVidas.titularVinculado)
			this.formPrimeiraInclusao.enable()
			this.formPrimeiraInclusao.reset({
				vida_tipo: this.opcoesTipoVida[+this.tipoVidaEnum.DEPENDENTE - 1],
				cli_codigoassociados4e: { value: this.dadosInclusaoVidas.codAssociadoS4e, descricao: this.dadosInclusaoVidas.titularVinculado },
				nome_associado_s4e: this.dadosInclusaoVidas.titularVinculado,
				fk_idtitular: this.dadosInclusaoVidas.idTitular,
				vida_empresa: this.dadosInclusaoVidas.nomeEmpresa,
				vida_idcompromisso: this.dadosInclusaoVidas.idCompromisso
			})
			this.formPrimeiraInclusao.get('vida_tipo')?.disable()
			this.formPrimeiraInclusao.get('cli_codigoassociados4e')?.disable()
			this.formPrimeiraInclusao.get('nome_associado_s4e')?.disable()
			this.titularSelecionado({
				value: this.dadosInclusaoVidas.idTitular,
				descricao: this.dadosInclusaoVidas.titularVinculado,
				cpf: this.dadosInclusaoVidas.cpfTitular,
				idEmpresaS4e: this.dadosInclusaoVidas.idEmpresaS4e,
				idEmpresa: this.dadosInclusaoVidas.idEmpresa
			})
		}
	}

	clearData() {
		this.dadosInclusaoVidas.idEmpresa = 0
		this.formPrimeiraInclusao.get('vida_cpf').setValue(null)
		this.formPrimeiraInclusao.get('vida_cpf')?.disable()
	}

	ngAfterViewInit() {
		this.getFormPrimeiraInclusao.vida_cpf.statusChanges
			.pipe(debounceTime(300), distinctUntilChanged())
			.subscribe(status => {
				if (status === TipoValidoOuInvalido.VALID) {
					this.buscarBeneficiarioPorEmpresaS4ECpf(this.dadosInclusaoVidas.idEmpresaS4e)
				}
			})
	}

	async buscarBeneficiarioPorEmpresaS4ECpf(codEmpresaS4E: number) {
		const cpfFormatado = this.getFormPrimeiraInclusao.vida_cpf.value.replace(
			patternCaracteresEspeciaiseEspaco, '')

		if (cpfFormatado) {
			this.buscandoCPF = true
			const { count = 0 } = await this.compromissoService.listarBeneficiariosAtivosPorFiltroeIdEmpresaS4E(
				codEmpresaS4E, cpfFormatado)

			if (count) {
				this.swalBeneficiarioJaCadastrado.openModal()
			} else {
				this.beneficiarioJaCadastrado = false
			}
			this.buscandoCPF = false
		}
	}

	tipoVidaSelecionado(item: TipoVidaSelect) {
		this.dadosInclusaoVidas.tipoVida = item.value || this.dadosInclusaoVidas.tipoVida
		this.formPrimeiraInclusao.reset({
			vida_tipo: item,
			vida_idcompromisso: this.dadosInclusaoVidas.idCompromisso,
			cli_grauparentesco: item.value === TipoTitularInclusaoVidas.TITULAR ? this.opcoesTipoVida[0] : null
		})

		this.setValidators('cli_codigoassociados4e',
			this.dadosInclusaoVidas.tipoVida === TipoTitularInclusaoVidas.DEPENDENTE ? [Validators.required] : [])

		this.setValidators('vida_matricula',
			this.dadosInclusaoVidas.tipoVida === TipoTitularInclusaoVidas.TITULAR && this.exigeMatricula ? [Validators.required] : [])
	}

	titularSelecionado(item: TitularSelect) {
		this.dadosInclusaoVidas.nomeTitular = item.descricao
		this.dadosInclusaoVidas.cpfTitular = item.cpf
		this.carregarDadosEmpresaVinculada(item.idEmpresa, item.idEmpresaS4e)
	}

	empresaVinculadaSelecionado(item: EmpresaSelect) {
		this.carregarDadosEmpresaVinculada(item.idEmpresa, item.idEmpresaS4e)
	}

	carregarDadosEmpresaVinculada(idEmpresa: number, idEmpresaS4E: number) {
		this.dadosInclusaoVidas.idEmpresaS4e = idEmpresaS4E
		this.dadosInclusaoVidas.idEmpresa = idEmpresa
		this.compromissoService.listarCompromissoVidaAvulsa(idEmpresa).then(data => {
			if (data) {
				this.dadosInclusaoVidas.idCompromisso = data.pk_idcompromisso
				this.dadosInclusaoVidas.nomeEmpresa = data.empresa?.emp_nomefantasia
				this.formPrimeiraInclusao.patchValue({
					vida_idcompromisso: this.dadosInclusaoVidas.idCompromisso
				})
				this.formPrimeiraInclusao.get('vida_cpf')?.enable()
			}
		})

		this.compromissoService.buscarPlanosPorIdEmpresaS4e(idEmpresaS4E).then(produtos => {
			this.onCarregarPlanoFromFormVidas.emit(produtos)
		})
	}

	listarEmpresasPorNomeCnpjOuCodigo = async (filtro: string) => {
		try {
			this.opcoesEmpresa = []
			if (filtro) {
				this.buscandoEmpresa = true
				this.opcoesEmpresa = (
					await this.compromissoService.listarEmpresasPorNomeCnpjOuCodigo({
						search: filtro,
						daysAgo: 60
					})
				).map(titular => ({
					value: titular.idempresa,
					descricao: titular.razaosocial,
					idEmpresaS4e: titular.idempresas4,
					idEmpresa: titular.idempresa
				}))
			}
		} catch (error) {
			this.apiService.showToast(
				null,
				retornarMsgErroPorStatus(error),
				'listarEmpresasPorNomeCnpjOuCodigo'
			)
		} finally {
			this.buscandoEmpresa = false
		}
	}

	listarBeneficiarioPorNomeOuCpf = async (filtro: string) => {
		try {
			this.opcoesTitulares = []
			if (filtro && filtro.length > 2) {
				this.buscandoColaboradoresTitulares = true
				this.opcoesTitulares = (
					await this.compromissoService.listarBeneficiarioPorNomeOuCpf({ search: filtro })
				).map(titular => ({
					value: titular.codigoAssociado,
					descricao: titular.nome + ' - ' + titular.razaoSocial,
					cpf: titular.cpf,
					idEmpresaS4e: titular.idEmpresaS4e,
					idEmpresa: titular.idEmpresa
				}))
			}
		} catch (error) {
			this.apiService.showToast(null, retornarMsgErroPorStatus(error), 'error')
		} finally {
			this.buscandoColaboradoresTitulares = false
		}
	}

	private setValidators(controlName: string, validators: ValidatorFn[]) {
		const control = this.formPrimeiraInclusao?.get(controlName)
		if (control) {
			control.setValidators(validators)
			control.updateValueAndValidity()
		}
	}

	fecharSwalBeneficiarioJaCadastrado() {
		this.beneficiarioJaCadastrado = true
		this.getFormPrimeiraInclusao.vida_cpf.setValue(null)
	}
}
